.label {
  text-align: left;
  margin-top: 6px;
  margin-bottom: 4 px;
}

.row-spacing {
  margin-top: 5px;
}

.axis-label-name {
  padding: 10px;
}

.box-container {
  border: solid black 2px;
  /* margin: 10px; */
}

.main-box {
  margin: 15px;
}

.upload-btn {
  text-align: right;
  margin: 10px;
}

.active {
  background: #d9cfb4;
  box-shadow: 0px 2px 15px #00000029;
}

.editor-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  background-color: #ccc;
  padding: 2px;
  border-radius: 4px;
  .editor-cst-btn {
    background-color: #a9a9a9;
    border: none;
    color: white;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-family: "Montserrat", sans-serif;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
    &.active {
      background-color: #ffffff;
      color: #000000;
    }
  }
}

.card-height {
  height: 70px;
}

.threejs-z canvas {
  max-height: 550px !important;
  width: 100% !important;
}

.browsing-btn {
  background-color: #5e72e4;
  width: 100%;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  #upload-photo {
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.loading-overlay {
  width: 100%;
  background: #00010c;
  opacity: 0.7;
  position: fixed;
  z-index: 9999;
  top: 0;
  height: 100%;
  overflow: hidden;
  flex-direction: (column);
  display: flex;
  justify-content: (center);
  align-items: (center);

  h2 {
    color: white;
    font-size: 1.5rem;
    text-transform: capitalize;
  }
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 99999;
  position: relative;
  // @include transform(rotateZ(45deg));
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  // @include transform(scale(1.1));
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
